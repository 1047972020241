
import { Options, mixins } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { TogglProject, TogglTimeEntry } from '@/store/toggl'
import { ZohoInvoiceProject, ZohoInvoiceTask } from '@/store/zoho-invoice'
import TerminalMixin from '@/mixins/Terminal'

import asyncForeach from '@/helpers/asyncForeach'
import { parseDuration, getDateString, getDatetimeString } from '@/helpers/datetime'
import uniqBy from 'lodash-es/uniqBy'

import TogglProjectSelect from '@/components/Toggl/ProjectSelect.vue'
import ZohoInvoiceProjectSelect from '@/components/ZohoInvoice/ProjectSelect.vue'
import LoadingComponent from '@/components/Loading.vue'
import ButtonComponent from '@/components/Form/Button.vue'
import Terminal from '@/components/Terminal.vue'

@Options({
  components: {
    TogglProjectSelect,
    ZohoInvoiceProjectSelect,
    LoadingComponent,
    ButtonComponent,
    Terminal
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      togglProjects: 'toggl/getProjects',
      timeEntries: 'toggl/getTimeEntries',
      zohoInvoiceProjects: 'zohoInvoice/getProjects',
      tasks: 'zohoInvoice/getTasks'
    })
  }
})
export default class Settings extends mixins(TerminalMixin) {
  isLoggedIn!: boolean
  togglProjects!: TogglProject[]
  timeEntries!: TogglTimeEntry[]
  zohoInvoiceProjects!: ZohoInvoiceProject[]
  tasks!: ZohoInvoiceTask[]

  loading = false
  togglProjectIds: number[] = [ ]
  zohoInvoiceProjectId: number | null = null

  get validForm (): boolean {
    return this.togglProjectIds.length > 0 && !!this.zohoInvoiceProjectId
  }

  get projectsLoaded (): boolean {
    return this.togglProjects.length > 0 && this.zohoInvoiceProjects.length > 0
  }

  async sync (): Promise<void> {
    this.loading = true

    this.addMessage('Get time-entries from toggl.com')
    await this.$store.dispatch('toggl/getTimeEntries', { projectIds: this.togglProjectIds, reset: true })
      .then(() => {
        this.addMessage('Done', {
          togglProjectIds: this.togglProjectIds,
          togglTimeEntries: this.timeEntries
        })
      })
      .catch(e => {
        this.loading = false
        this.addMessage('An error appeared: ' + e.message, e, 'error')

        throw Error(e.message)
      })

    this.addMessage('Get current user from ZohoInvoice')
    await this.$store.dispatch('zohoInvoice/getUser')
      .then((r) => { this.addMessage('Done', r) })

    this.addMessage('Get tasks from ZohoInvoice')
    await this.$store.dispatch('zohoInvoice/getTasks', this.zohoInvoiceProjectId)
      .then(() => {
        this.addMessage('Done', {
          zohoInvoiceProjectId: this.zohoInvoiceProjectId,
          zohoInvoiceTasks: this.tasks
        })
      })

    this.addMessage('Synchronizing tasks with ZohoInvoice')
    await this.syncZohoInvoiceTasks()
      .then((response) => {
        this.addMessage('Done', {
          zohoInvoiceProjectId: this.zohoInvoiceProjectId,
          response
        })
      })

    this.addMessage('Synchronizing time-entries with ZohoInvoice')
    await this.syncZohoInvoiceTimeEntries()
      .then(() => {
        if (this.hasErrors) {
          this.addMessage('Done with errors during sync')
          return
        }

        this.addMessage('Done - All time-entries are synchronized to ZohoInvoice')
      })

    this.loading = false
  }

  getTogglProjectNameById (id: number): string {
    return this.togglProjects.find(p => p.id === id)?.name || id.toString()
  }

  async syncZohoInvoiceTasks (): Promise<void> {
    const tasksFromTimeEntries = uniqBy(this.timeEntries, 'project_id')
    const newTasks = tasksFromTimeEntries.map(t => {
      const togglProjectName = this.getTogglProjectNameById(t.project_id)
      return this.togglProjects.find(p => p.name === togglProjectName) as TogglProject
    }).filter(p => !p ? false : !this.tasks.map(t => t.task_name).includes(p.name))

    newTasks.map(task => {
      task.rate = task.rate || 0
      return task
    })

    await asyncForeach<TogglProject>(newTasks, async (item) => {
      return this.$store.dispatch('zohoInvoice/addTask', {
        projectId: this.zohoInvoiceProjectId,
        taskName: item.name,
        rate: item.rate
      }).then((r) => {
        if (r.task) {
          this.$store.commit('zohoInvoice/addTask', r.task)
        }
      })
    })
  }

  async syncZohoInvoiceTimeEntries (): Promise<void> {
    const timeEntries = this.timeEntries.map((t) => {
      const projectName = this.getTogglProjectNameById(t.project_id)
      const task = this.tasks.find(task => task.task_name === projectName)
      const timeEntry = t.time_entries[0]

      const zohoTimeEntry = {
        projectId: this.zohoInvoiceProjectId,
        description: getDatetimeString(timeEntry.start, 'DD.MM.YYYY') + '\n' + t.description + '\n' + t.tags.join(', '),
        duration: parseDuration(timeEntry.seconds),
        date: getDateString(timeEntry.start),
        taskId: task?.task_id || ''
      }

      return zohoTimeEntry
    })

    this.addMessage(`Prepared ${timeEntries.length} time-entries`)
    await asyncForeach(timeEntries, async (item, i) => {
      return this.$store.dispatch('zohoInvoice/addTiming', item).then(r => {
        if (r.code !== 0) {
          this.addMessage(`Error: ${i + 1}/${timeEntries.length} time-entries: ${item.description}`, r, 'error')
          return
        }

        this.addMessage(`Added ${i + 1}/${timeEntries.length} time-entries: ${item.description}`, r)
      })
    })
  }
}
