<template>
  <div class="flex flex-wrap">
    <label-component for-name="togglProjects">
      From Toggl.com projects:
    </label-component>
    <multiselect-component
      id="togglProjects"
      v-model="value"
      :options="projectOptions"
      class="w-full border mb-4"
    />
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { TogglProject, TogglClient } from '@/store/toggl'

import LabelComponent from '@/components/Form/Label.vue'
import MultiselectComponent from '@/components/Form/Multiselect.vue'

@Options({
  components: {
    LabelComponent,
    MultiselectComponent
  },
  computed: {
    ...mapGetters({
      userLoaded: 'user/loaded',
      projects: 'toggl/getProjects',
      clients: 'toggl/getClients'
    })
  }
})
export default class TogglProjectSelect extends Vue {
  userLoaded!: boolean
  projects!: TogglProject[]
  clients!: TogglClient[]

  @Prop() modelValue!: number[]

  get value (): number[] {
    return this.modelValue
  }

  set value (value: number[]) {
    this.$emit('update:modelValue', value)
  }

  get projectOptions (): { label: string, value: number }[] {
    return this.projects.map(p => {
      const client = this.clients.find(c => c.id === p.cid)
      return {
        label: `${p.name} - ${client?.name}`,
        value: p.id
      }
    })
  }

  mounted (): void {
    this.loadData()
    this.$emitter.on('onAuthStateApproved', this.loadData)
  }

  destroyed (): void {
    this.$emitter.off('onAuthStateApproved', this.loadData)
  }

  async loadData (): Promise<any> {
    if (!this.userLoaded) return
    await this.$store.dispatch('toggl/getClients')
    await this.$store.dispatch('toggl/getProjects')
    await this.$store.dispatch('toggl/getTags')
  }

  // eslint-disable-next-line
  change (e: any): void {
    const values: number[] = []
    e.target.selectedOptions.forEach((o: HTMLOptionElement) => {
      values.push(parseInt(o.value))
    })
    this.$emit('update:modelValue', values)
  }
}
</script>
