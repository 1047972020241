
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { ZohoInvoiceProject } from '@/store/zoho-invoice'

import LabelComponent from '@/components/Form/Label.vue'
import SelectComponent from '@/components/Form/Select.vue'

@Options({
  components: {
    LabelComponent,
    SelectComponent
  },
  computed: {
    ...mapGetters({
      userLoaded: 'user/loaded',
      projects: 'zohoInvoice/getProjects'
    })
  }
})
export default class TogglProjectSelect extends Vue {
  userLoaded!: boolean
  projects!: ZohoInvoiceProject[]

  @Prop() modelValue!: number[]

  get value (): number[] {
    return this.modelValue
  }

  set value (value: number[]) {
    this.$emit('update:modelValue', value)
  }

  get projectOptions (): { label: string, value: number }[] {
    if (!this.projects) return []
    return this.projects.map(p => {
      return {
        label: p.project_name,
        value: p.project_id
      }
    })
  }

  mounted (): void {
    this.loadData()
    this.$emitter.on('onAuthStateApproved', this.loadData)
  }

  destroyed (): void {
    this.$emitter.off('onAuthStateApproved', this.loadData)
  }

  async loadData (): Promise<any> {
    if (!this.userLoaded) return
    await this.$store.dispatch('zohoInvoice/getProjects')
  }
}
