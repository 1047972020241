
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Multiselect extends Vue {
  @Prop() id!: string
  @Prop() modelValue!: number[]
  @Prop() options!: { value: string, label: string }[]

  get value (): number[] {
    return this.modelValue
  }

  set value (value: number[]) {
    this.$emit('update:modelValue', value)
  }
}
