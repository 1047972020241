<template>
  <div class="w-full text-base placeholder-gray-600 border rounded outline-none appearance-none focus:border-black">
    <template
      v-for="(option, i) in options"
      :key="i"
    >
      <label
        class="flex px-3 pb-2 text-base text-gray-700 cursor-pointer"
        :class="{ 'pt-2': i === 0 }"
      >
        <input
          :id="id"
          v-model="value"
          type="checkbox"
          :value="option.value"
          class="hidden"
        >
        <div
          class="flex items-center justify-center my-1 mr-2 border rounded"
          style="width: 14px; height: 14px"
        >
          <svg
            v-if="modelValue.includes(option.value)"
            class="w-2 h-2 fill-current"
            viewBox="0 0 80.588 61.158"
          >
            <path
              d="M29.658,61.157c-1.238,0-2.427-0.491-3.305-1.369L1.37,34.808c-1.826-1.825-1.826-4.785,0-6.611,c1.825-1.826,4.786-1.827,6.611,0l21.485,21.481L72.426,1.561c1.719-1.924,4.674-2.094,6.601-0.374,c1.926,1.72,2.094,4.675,0.374,6.601L33.145,59.595c-0.856,0.959-2.07,1.523-3.355,1.56C29.746,61.156,29.702,61.157,29.658,61.157z"
            />
          </svg>
        </div>
        <div>
          {{ option.label }}
        </div>
      </label>
    </template>
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Multiselect extends Vue {
  @Prop() id!: string
  @Prop() modelValue!: number[]
  @Prop() options!: { value: string, label: string }[]

  get value (): number[] {
    return this.modelValue
  }

  set value (value: number[]) {
    this.$emit('update:modelValue', value)
  }
}
</script>
